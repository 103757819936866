// index.js
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import LoadingPage from "./components/Loadingpage/Loadingpage"; // Import the LoadingPage component

const LazyApp = React.lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingPage />}>
      <LazyApp />
    </Suspense>
  </React.StrictMode>
);
